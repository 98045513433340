import BasePerimeter from "./BasePerimeter"

export default new BasePerimeter({
  purpose: "baseRoute",
  govern: {
    "can route": function (fullPath) {
      if (this.isSuperAdmin() || this.isWebsiteBuilder()) {
        return true
      } else {
        this.setFullPath(fullPath)
        return this.canView()
      }
    },

    "can viewRoute": function (fullPath) {
      if (this.isSuperAdmin() || this.isWebsiteBuilder()) {
        return true
      } else {
        this.setFullPath(fullPath)
        return this.canView()
      }
    },

    "can viewSuperAdmin": function () {
      return this.isSuperAdmin()
    },
    "can viewDashRedirect": function (fullPath) {
      // console.log(fullPath)
      if (this.isSuperAdmin() || this.isWebsiteBuilder()) {
        return true
      } else {
        // this.setFullPath(fullPath)
        return this.canViewDashRedirect()
      }
    },

    "can viewSuperSuperAdmin": function () {
      return this.isSuperSuperAdmin()
    },

    "can viewNoWebsiteBuilder": function (fullPath) {
      if (this.isSuperAdmin()) {
        return true
      } else if (this.isWebsiteBuilder()) {
        return false
      } else {
        this.setFullPath(fullPath)
        return this.canView()
      }
    },
    "can viewWebsiteBuilder": function (fullPath) {
      if (this.isSuperAdmin()) {
        return true
      } else if (this.isWebsiteBuilder()) {
        return true
      } else {
        return false
      }
    },

    "can websiteBuilder": function () {
      if (this.isWebsiteBuilder()) {
        return true
      } else {
        return this.isWebsiteBuilder()
      }
    },

    "can editData": function () {
      if (this.isSuperAdmin() || this.isWebsiteBuilder()) {
        return true
      } else {
        return this.canEdit()
      }
    },

    "can deleteData": function () {
      if (this.isSuperAdmin() || this.isWebsiteBuilder()) {
        return true
      } else {
        return this.canDelete()
      }
    },

    "can superAdmin": function () {
      if (this.isSuperAdmin() === undefined) {
        return this.isSuperAdmin()
      } else {
        return this.isSuperAdmin()
      }
    },

    "can superSuperAdmin": function () {
      return this.isSuperSuperAdmin()
    },
  },
})

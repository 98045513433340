import { HeadGoverness } from 'vue-kindergarten'

export default class RouteGoverness extends HeadGoverness {
  async guard(action, context) {
    let isNotAllowed = this.isNotAllowed(action, context.route.path)
    // console.log(action, context, isNotAllowed)
    if (isNotAllowed) {
      context.redirect('/404')
    }
  }
}

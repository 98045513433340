
import { mapGetters, mapActions } from 'vuex'
import EditorSidebarHashbrown from '@/dashboard/website-editor/editor-sidebar-hashbrown'

export default {
  components: {
    EditorSidebarHashbrown
  },
  props: {
    /**
     * Title of editor sidebar
     */
    title: {
      type: String,
      default: 'Page Styles'
    }
  },
  computed: {
    ...mapGetters({
      showEditorSidebar: 'editorSidebar/getShowEditorSidebar',
      toggleEditorSidebar: 'editorSidebar/getToggleEditorSidebar',
      formSettings: 'editorSidebar/getFormSettings'
    })
  },
  methods: {
    ...mapActions({
      onToggleSidebar: 'editorSidebar/toggleEditorSidebar',
      onUpdateFormSettings: 'editorSidebar/updateFormSettings'
    }),
    /**
     * Pass dummy formButton settings to Editor Sidebar
     */
    onEditButton() {
      this.onUpdateFormSettings(this.settings)
      // this.onToggleSidebar();
    }
  }
}

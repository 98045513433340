
var pjson = require("@/package.json")


export default {
  components: {
  },
  data() {
    return {
      year: new Date().getFullYear(),
      version: 1
    }
  },
  created() {
    this.version = pjson.version
  },
  methods: {

  }
}

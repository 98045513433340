
import { mapGetters, mapActions } from "vuex"
import SidebarPotato from "@/dashboard/sidebar/sidebar-potato"
import InsightsMobilenav from "@/dashboard/sidebar/InsightsMobilenav"
import InsightsSidenav from "@/dashboard/sidebar/InsightsSidenav"
import * as _ from "lodash"

export default {
  components: {
    SidebarPotato,
    InsightsSidenav,
    InsightsMobilenav
  },
  props: {
    shouldHide: {
      type: Boolean,
      default: false
    }
  },
  mounted() { },
  methods: {
    /**
     *
     * Toggle sidebar state
     */
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    }
  }
}

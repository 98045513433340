
import { FadeTransition } from 'vue2-transitions'
import FeatherIcon from "@/helpers/FeatherIcon"
import _ from "lodash"

export default {
  name: 'base-alert',
  components: {
    FadeTransition,
    FeatherIcon
  },
  props: {
    type: {
      type: String,
      default: 'default',
      description: 'Alert type'
    },
    dismissible: {
      type: Boolean,
      default: true,
      description: 'Whether alert is dismissible (closeable)'
    },
    autoHideDelay: {
      type: Number
    },
    icon: {
      type: String,
      default: 'check-circle',
      description: 'Alert icon to display'
    },
    title: {
      type: String
    },
    text: {
      type: String
    },
  },
  data() {
    return {
      visible: true
    }
  },
  mounted() {
    if (this.autoHideDelay) {
      _.delay(() => {
        this.dismissAlert()
      }, this.autoHideDelay)
    }
  },
  methods: {
    dismissAlert() {
      this.visible = false
    }
  }
};


import { EventBus } from "@/utils/event-bus"

export default {
  name: 'IdleLayover',
  components: {
  },
  data() {
    return {
      isIdle: false,
    }
  },
  created() {
    EventBus.$on('idleVue_onIdle', (e) => {
      this.isIdle = true
    })
    EventBus.$on('idleVue_onActive', (e) => {
      // this.isIdle = false
    })
  },
  methods: {
    onIdle() {
      this.$refs.sprite.play()
    },
    onActive() {
      this.$refs.sprite.stop()
    },
    reload() {
      window.location.reload()
    }
  }
}

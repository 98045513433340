
import NavbarBanana from "@/dashboard/navigation/navbar-banana"
import { Auth } from 'aws-amplify'
import { ApiFactory } from "@/utils/apis/ApiFactory"
const SitelinkAPI = ApiFactory.get("sitelink")
import { mapMutations, mapGetters, mapActions } from "vuex"

export default {
  components: {
    NavbarBanana
  },
  props: {
    isContainerFluid: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      searchResults: "",
      clickResults: null,
      navbar: {
        showSearch: false,
        showNotifications: true,
        baseHref: "/accounts",
        menu: [

        ],
        notifications: []
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      selectedAccount: "accounts/getSelectedAccount",
      accountId: "user/getSelectedAccountId",
      user: "user/getUser",
      currentFcmToken: "user/getCurrentFcmToken",
    }),
    getTotalUnreadNotifications() {
      if (this.navbar && this.navbar.notifications) {
        return (
          this.navbar.notifications.filter((notification) => {
            // eslint-disable-next-line no-unused-expressions
            notification.unread === true
          }).length || 0
        )
      } else {
        return 0
      }
    }
  },
  created() {
    this.setMenu()
  },
  watch: {
    selectedAccount: {
      handler() {
        this.setMenu()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      setUser: "user/setUser",
      userLocationsAndAccess: 'user/userLocationsAndAccess'
    }),
    ...mapMutations({
      updateUnitCacheStatus: "cache/updateUnitCacheStatus",
    }),
    async setMenu() {
      this.navbar.menu = [{
        type: "security",
        icon: "key",
        text: "Security",
        link: "/my-account/security"
      },
      {
        type: "divider"
      },
      {
        icon: "log-out",
        text: "Logout",
        link: "",
        action: "logout"
      }]
      if (this.$isAllowed("superAdmin")) {
        this.navbar.menu.unshift({
          type: "user-settings",
          icon: "bell",
          text: "Notifications",
          link: `/my-account/notification-settings`,
        })
      }
      let userLocations = await this.userLocationsAndAccess()
      if (this.selectedAccount && this.selectedAccount.id && (userLocations.hasFullAccess || this.$isAllowed("superAdmin"))) {
        this.navbar.menu.unshift({
          type: "teammates",
          icon: "users",
          text: "Teammates",
          link: `/teammates/${ this.selectedAccount.id }`,
        })
      }
      if (this.selectedAccount && !this.selectedAccount.use_insights_unit_manager) {
        this.navbar.menu.unshift({
          type: "divider"
        })
        this.navbar.menu.unshift({
          type: "update_units",
          icon: "refresh-cw",
          text: "Update website units",
          action: `update_units`,
        })
      }
      if (this.$isAllowed("superAdmin")) {
        this.navbar.menu.unshift({
          type: "divider"
        })
        this.navbar.menu.unshift({
          type: "keyboard-shortcuts",
          icon: "coffee",
          text: "Keyboard shortcuts"
        })
        this.navbar.menu.unshift({
          type: "website-color-palette",
          icon: "sun",
          text: "Website Colors"
        })
        this.navbar.menu.unshift({
          type: "divider"
        })

        this.navbar.menu.unshift({
          type: "users",
          icon: "user",
          text: "Users",
          link: "/users"
        })
        this.navbar.menu.unshift({
          type: "dashboard-global",
          icon: "pie-chart",
          text: "Admin dashboard",
          link: "/accounts/admin-dashboard"
        })
        this.navbar.menu.unshift({
          type: "calendar-global",
          icon: "calendar",
          text: "Admin calendar",
          link: "/accounts/admin-calendar"
        })
      }
    },
    onSearch(event) {
      this.searchResults = event
    },
    async onMenuItemClicked(event) {
      this.clickResults = event
      if (event.action === "logout") {
        this.setUser(null)
        this.delete_cookie("pugg_dash")
        this.$storage.remove("user")
        firebase.auth().signOut()
        this.$store.dispatch('user/setCurrentFcmToken', null)
        this.$router.push("/login")
      }
      else if (event.action === "update_units") {
        this.updateCache()
      }
    },
    async updateCache() {
      try {
        await SitelinkAPI.updateCache({ accountId: this.selectedAccount.id, body: { user_name: this.user.name, user_email: this.user.email, user_id: this.user.id, account_name: this.selectedAccount.name, fcm_token: this.currentFcmToken } })
        this.$root.setWebsiteChanges(`Cache Update Started for ${ this.selectedAccount.name }`, null, 'unit_cache')
        this.toastSuccess("Started refreshing website units")
        if (this.user.is_super_admin) {
          this.updateUnitCacheStatus({
            account_id: this.selectedAccount.id,
            status: "started",
          })
        }
      } catch (e) {
        this.toastError("Error refreshing website units")
        this.$rollbar.error('Error updating unit cache', e, { account_id: this.selectedAccount.id })
      }
    },
    delete_cookie(name) {
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;"
    }
  }
}


export default {
  name: "FeatherIcon",
  props: {
    icon: {
      type: String,
      default: "mouse-pointer",
    },
    width: {
      type: [String, Number],
      default: 20,
    },
    height: {
      type: [String, Number],
      default: 20,
    },
    classCustom: {
      type: String,
      default: "",
    },
  },
}
